import React from "react";
import Layout from "../../components/motorrad/layout";
import SEO from "../../components/motorrad/seo";
import FlexCard from "../../components/motorrad/flexcard";
import ImageBackground from '../../components/imagebackground';
import settings from "../../../settings";

const topbannerDesktop = `${settings.IMAGES_BASE_URL}/images/motorrad/experiences/moto-experience-banner.jpg`;
const bootCampBanner = `${settings.IMAGES_BASE_URL}/images/motorrad/experiences/boot-camp.jpg`;
const gsTrophyBanner = `${settings.IMAGES_BASE_URL}/images/motorrad/experiences/team-USA.jpg`;


class Experiences extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="The BMW U.S. Rider Academy | Motorrad Tours and Classes"
          description="The BMW U.S. Rider Academy offers custom motorrad tours, riding classes – on and off-road, of course, and the chance to ride and try the latest BMW motorcycles."
        />
        <ImageBackground
          imageDesktop={topbannerDesktop}
          imageMobile={topbannerDesktop}
          imageAlt="BMW Classes Dirty Tire Top Banner"
          // scrollTo={scrollToContent}
        />
        <div className="experiences">
          <section className="performance-center">
            <div className="container">
              <div className="performance-center__header">
                <h1 className="motorrad__title">BMW RIDING EXPERIENCES</h1>
                <p>
                  These unique offerings will push you to new heights with multiple days of on-road and off-road training, working with BMW Team USA and more.
                </p>
              </div>
            </div>
            
            <FlexCard
              rowType="row"
              image={bootCampBanner}
              imageAlt="FIVE-DAY MOTORCYCLE BOOT CAMP"
              header="FIVE-DAY MOTORCYCLE BOOT CAMP"
              subheader="$3,825 - Using our BMW bikes"
            >
              <p>
                It’s five days of thrilling motorcycle adventure. Boot Camp is split up into three sections: two days of On-Road training, two days of Off-Road training and a final day of off-site adventure riding. The variety of surfaces and varying riding techniques will challenge you like never before.
              <br/><br/>Sessions are limited to groups of ten. Lunch will be provided each day.
              </p>
              <div className="button__links">
                <a href="tel:1-888-345-4269" target="_blank" rel="noopener noreferrer"><button className={`btn btn-bordered`}>Call 1-888-345-4269 to reserve your seat now</button></a>
              </div>
            </FlexCard>
            <br/><br/>
            <FlexCard
              rowType="row"
              image={gsTrophyBanner}
              imageAlt="TWO-DAY TRAIN WITH GS TROPHY TEAM USA"
              header="TWO-DAY TRAIN WITH GS TROPHY TEAM USA"
              subheader="$1,800 - Using our BMW bikes"
            >
              <p>
                There’s no better way to get a head start on your GS Trophy training than with Team USA as they prepare for Namibia. Riders will receive focused instruction on key skills needed to succeed in the GS Trophy Qualifier. Expect in-depth training on tight turning, trail stops, power slide, brake sliding, elephant turns and much more. Rider skills will then be tested with team challenges
              <br/><br/>Limited seats available.
              <br/><br/>Dates: May 28-29, June 3-4, August 12-13
              </p>
              <div className="button__links">
                <a href="tel:1-888-345-4269" target="_blank" rel="noopener noreferrer"><button className={`btn btn-bordered`}>Call 1-888-345-4269 to reserve your seat now</button></a>
              </div>
            </FlexCard>
          </section>
        </div>
      </Layout>
    );
  }
}
export default Experiences;